body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f5f7fa;
  color: #3c3b3d;
}
.react-datepicker__input-container input {
  width: 100%;
  height: 32px;
  padding-left: 8px;
  font-size: 16px;
  display: block;
  border: 1px solid #3c3b3d;
  border-radius: 3px;
  box-shadow: none;
}
.react-datepicker__input-container input:focus {
  outline: #3c3b3d;
  border: 1px solid #2aba66;
}
.react-datepicker-wrapper {
  width: 100%;
}

/* @media only screen and (max-width: 400px) {

} */
